<template>
  <v-app>
  <div>
    <title>สมัครสมาชิก</title>
    <img  v-if="page <= 3"
      src="../assets/image/UI Member PHOTO/Banner-03.png"
      class="img-fluid"
      alt="Responsive image"
      style="width: 100%"
    />
    <template v-if="page == 1 ">
      <div>
        <CCard style="width: 95%; margin: 20px auto;">
          <CCardBody style="background-color:#e5e5e5; padding: 10px;">
            <div>
              <div style="display:flex;">
                <div style="background-color:#8350ff; width: 20px; height: 20px; border-radius: 50%; margin: 0 5px;">
                  <h1 style="font-size:16px; color:#FFF; margin: 0 0 0 7px;">1</h1>
                </div>
                <h1 style="font-size:18px;">เลือกสาขาที่ใช้บริการประจำ</h1>
              </div>
              <v-skeleton-loader style="height: 35px;" v-if="branchWW.length <= 1" type="image"></v-skeleton-loader>
              <CSelect v-else @change="onChangeBranch($event)" :options="branchWW" style="padding: 0 30px 0 30px;" />
              <template v-if="warningText.branch != ''">
                <p style="color:#FF0000; margin: 0 0 0 0; width: 210px;">{{warningText.branch}}</p>
              </template>
            </div>
          </CCardBody>
        </CCard>
      </div>

      <div>
        <CCard style="width: 95%; margin: 20px auto;">
          <CCardBody style="background-color:#e5e5e5; padding: 10px;">
            <div style="display:flex;">
              <div style="background-color:#8350ff; width: 20px; height: 20px; border-radius: 50%; margin: 0 5px;">
                <h1 style="font-size:16px; color:#FFF; margin: 0 0 0 6px;">2</h1>
              </div>
              <h1 style="font-size:18px;">กรอก หมายเลขโทรศัพท์มือถือ</h1>
            </div>
            <v-skeleton-loader style="height: 35px;" v-if="branchWW.length <= 1" type="image"></v-skeleton-loader>
            <CInput 
              v-else
              type="number" 
              v-model="numberphone" 
              @input="cntnumberPhone()" 
              placeholder="หมายเลขโทรศัพท์" 
              aria-label="หมายเลขโทรศัพท์" 
              aria-describedby="basic-addon1" 
              style="font-family: Kanit, sans-serif; padding: 0 30px 0 30px;"
              oninput=" this.value = (this.value.length > 10) ? this.value.slice(0,10) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);"
            ></CInput>
            <template v-if="warningText.numberphone != ''">
              <p style="color:#FF0000; margin: 0 0 0 0; width: 100%; padding: 0 30px 0 30px;">{{warningText.numberphone}}</p>
            </template>
            <template v-if="APIreply_Checkmemberstatus.refNumber != '' && warningText.numberphone == ''">
              <div style="padding: 0 30px 0 30px;">
              <div style="display: flex;"> 
                <div style="padding-right: 5px;">
                  <CImg :src="iconpage1_num" style="width: 20px"/>
                </div>
                <p>{{textCheck.numberphone}}</p>
              </div>

              <div style="display: flex;">
                <div style="padding-right: 5px;">
                  <img :src="iconpage1_pin" style="width: 20px"/>
                </div>
                <p>{{textCheck.pin}}</p>
              </div>
              </div>
            </template>
          </CCardBody>
        </CCard>
      </div>

      <div style="display: flex; justify-content: center; margin: 0 0 50px 0;">
        <CButton style="background-color:#FF5C57; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
          <p style="margin: 0 10px;">ยกเลิก</p>
        </CButton>
        <CButton v-on:click="changeToPage2()" style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
          <p style="margin: 0 10px;">ตกลง</p>
        </CButton>
      </div>

    </template>

    <template v-else-if="page == 2">
      <div>
        <CCard style="width: 95%; margin: 20px auto;">
          <CCardBody style="background-color:#e5e5e5; padding: 10px;">
            <div style="display:flex;">
              <div style="background-color:#8350ff; width: 20px; height: 20px; border-radius: 50%; margin: 0 5px;">
                <h1 style="font-size:16px; color:#FFF; margin: 0 0 0 6px;">3</h1>
              </div>
              <h1 style="font-size:18px;">กรอก หมายเลข OTP 6 หลัก</h1>
            </div>
            <div style="padding: 0 30px 0 30px;">
            <center>
              <p style="font-size: 14px; color: #7d7d7d">
                หมายเลข OTP ถูกส่งไปยังเบอร์
              </p>
              <h2 style="font-size: 16px">{{ numberphone }}</h2>
              <p style="font-size: 14px; color: #7d7d7d">
                โปรดดูหมายเลข OTP ที่ข้อความ SMS
              </p>
            </center>
            
            <CInput 
              type="number" 
              v-model="codeOTP" 
              placeholder="otp" 
              aria-label="otp" 
              aria-describedby="basic-addon1" 
              style="font-family: Kanit, sans-serif;"
              oninput=" this.value = (this.value.length > 6) ? this.value.slice(0,6) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);"
            ></CInput>
            <div style="display: flex; justify-content:flex-end;">
              <a v-on:click="SendOTPAgain()"><u :style="cssOTP">ส่ง OTP อีกครั้ง({{timeoutOtp}})</u></a>
            </div>
            <template v-if="this.checkConditionlist.otp">
              <p style="color:#FF0000; margin: 0 0 0 0;">*กรุณาใส่รหัส OTP ที่ถูกต้อง</p>
            </template>
            </div>
          </CCardBody>
        </CCard>
      </div>

      <div style="display: flex; justify-content: center; margin: 0 0 50px 0;">
        <CButton v-on:click="Cancel()" style="background-color:#FF5C57; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
          <p style="margin: 0 10px;">ยกเลิก</p>
        </CButton>
        <CButton v-on:click="changeToPage3()" style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
          <p style="margin: 0 10px;">ตกลง</p>
        </CButton>
      </div>
    </template>

    <template v-else-if="page == 3">
      <div v-if="newuser == false">
        <CCard style="width: 95%; margin: 20px auto;">
          <CCardBody style="background-color:#e5e5e5; padding: 10px;">
            <div style="display:flex;">
                <div style="background-color:#8350ff; width: 20px; height: 20px; border-radius: 50%; margin: 0 5px;">
                  <h1 style="font-size:18px; color:#FFF; margin: 0 0 0 4px;">4</h1>
                </div>
                <h1 style="font-size: 16px">กรอกรหัส Pin 4 หลัก</h1>
            </div>

            <div style="padding: 0 30px 0 30px;">
              <template v-if="checkConditionlist.pin && !showWrongPin">
                <p style="color:#FF0000; margin: 0 0 0 0;">*กรุณาใส่ Pin</p>
              </template>
              <CInput 
                type="password" 
                v-model="pin1" 
                @input="checkPin()" 
                placeholder="pin" 
                aria-label="pin" 
                aria-describedby="basic-addon1" 
                style="font-family: Kanit, sans-serif;"
                oninput=" this.value = (this.value.length > 4) ? this.value.slice(0,4) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);"
              ></CInput>
              <div style="display: flex;">
                <template v-if="pinmode == false">
                  <p style="color:#FF0000; margin: 0 0 0 0; width: 210px;">{{warningText.pin}}</p>
                  <div style="width: 100%; display: flex; text-decoration: underline; justify-content:flex-end;" @click="DialogForgotPassword()">
                    <p :style="dataForgetPin.css" >ลืมรหัส PIN</p>
                    <p v-if="dataForgetPin.timeoutForgetPin!=0" :style="dataForgetPin.css">({{dataForgetPin.timeoutForgetPin}})</p>
                  </div>
                </template>
              </div>
              
            </div>
            
            <template v-if="pinmode == true">
              <div style="padding: 0 30px 0 30px;">
                <h1 style="font-size: 16px">กรอกรหัส Pin 4 หลักอีกครั้ง</h1>
                <CInput 
                  type="password" 
                  v-model="pin2" 
                  @input="checkPin()" 
                  placeholder="pin" 
                  aria-label="pin" 
                  aria-describedby="basic-addon1" 
                  style="font-family: Kanit, sans-serif; "
                  oninput=" this.value = (this.value.length > 4) ? this.value.slice(0,4) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);"
                ></CInput>
              </div>
              <template v-if="showWrongPin">
                <div style="display: flex;">
                  <div style="padding-right: 5px;">
                    <img src="../assets/image/UI Member PHOTO/Icon-07.png" style="width: 20px"/>
                  </div>
                  <div >
                    <p style="color:#FF0000;">Pin ไม่ตรงกัน</p>
                  </div>
                </div>
              </template>
            </template>
          </CCardBody>
        </CCard>
      </div>
      
      <template v-if="newuser">
        <div>
          <CCard style="width: 95%; margin: 20px auto;">
            <CCardBody style="background-color:#e5e5e5; padding: 10px;">
              <div style="display:flex;">
                <div style="background-color:#8350ff; width: 20px; height: 20px; border-radius: 50%; margin: 0 5px;">
                  <h1 style="font-size:16px; color:#FFF; margin: 0 0 0 6px;">5</h1>
                </div>
                <h1 style="font-size: 18px">กรอกข้อมูลส่วนตัว</h1>
              </div>
              <div style="padding: 0 30px 0 30px;">
              <h2 style="font-size: 14px; padding-top: 10px">ชื่อ - นามสกุล</h2>
              <template v-if="this.checkConditionlist.name">
                  <p style="color:#FF0000; margin: 0 0 0 0;">*กรุณาใส่ชื่อนามสกุล</p>
              </template>
              <CInput type="text" v-model="name" @input="checkName()" style="font-family: Kanit, sans-serif;"></CInput>
              <h2 style="font-size: 14px; padding-top: 10px;">เพศ</h2>
              <template v-if="checkConditionlist.sex">
                <p style="color:#FF0000; margin: 0 0 0 0;">*กรุณาระบุเพศ</p>
              </template>
              <CCard>
                <!-- <CCardBody>
                  <CInputRadioGroup @update:checked="selectSex($event)" :options="[{ value: 'female', label: 'หญิง'},{ value: 'male', label: 'ชาย'},{ value: 'other', label: 'อื่นๆ'},]" style="font-family: Kanit, sans-serif;" />
                </CCardBody> -->
                <CCardBody @change="SelectSex()">
                  <input type="radio" id="female" name="sex" value="female">
                  <label for="css"><h2 style="font-size: 14px; margin: 0 0 0 10px;">หญิง</h2></label><br>
                  <input type="radio" id="male" name="sex" value="male" style="margin: 0;">
                  <label for="html"><h2 style="font-size: 14px; margin: 0 0 0 10px;">ชาย</h2></label><br>
                  <input type="radio" id="not specified" name="sex" value="not specified">
                  <label for="javascript"><h2 style="font-size: 14px; margin: 0 0 0 10px;">ไม่ระบุ</h2></label>
                </CCardBody>
              </CCard>
              <h2 style="font-size: 14px">วันเกิด (วันเดือนปี)</h2>
              <template v-if="this.checkConditionlist.bod">
                 <p style="color:#FF0000; margin: 0 0 0 0;">*กรุณาระบุวันเดือนปีเกิด</p>
              </template>

              <!-- <div>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <CInput
                        disabled
                        :placeholder="dateShow"
                        v-model="bod"
                        style="width: 100%; font-family: Kanit, sans-serif;"
                      />
                    </div>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="date"
                    min="1950-01-01"
                    locale="th"
                    @change="save()"
                    style="font-family: Kanit, sans-serif;"
                  ></v-date-picker>
                </v-menu>
              </div> -->
              <div style="width: 100%; ">
                <!-- <date-picker v-model="bod" type="date" lang='th' :placeholder="dateShow" style="width: 100%;"  @change="save" ref="picker"></date-picker> -->
                <date-picker
                  :placeholder="dateShow"
                  v-model="date"
                  min="1950-01-01"
                  locale="th"
                  style="font-family: Kanit, sans-serif; width: 100%;">
                </date-picker>
              </div>
              <br/>

              <h1 style="font-size: 16px">จังหวัดที่อาศัยอยู่</h1>
              <template v-if="checkConditionlist.province">
                <p style="color:#FF0000; margin: 0 0 0 0;">*กรุณาระบุจังหวัด</p>
              </template>
              <CSelect @change="Province($event)" :options="provincee" />
              </div>
            </CCardBody>
          </CCard>
        </div>

        <div>
          <CCard style="width: 95%; margin: 20px auto;">
            <CCardBody :style="cssWraning">
              <div style="display:flex;">
                <div style="background-color:#8350ff; width: 20px; height: 20px; border-radius: 50%; margin: 0 5px;">
                  <h1 style="font-size:18px; color:#FFF; margin: -1px 0 0 4px;">6</h1>
                </div>
                <h1 style="font-size: 16px">ยอมรับเงื่อนไขในการบริการ</h1>
              </div>
   
              <div style="display: flex; padding: 0 30px 0 30px;">
                <div style="padding-top: 3px; padding-right: 10px; ">
                  <input type="checkbox" id="checkCondition" :style="checkmark"  @click="checkBox()">
                </div>
                <div style="display: row">
                  <label>
                    ยอมรับ<a style="color:#8553fe; text-decoration: underline;" target="_blank" href="https://line.teedev.online/files/TermsOfService.pdf">เงื่อนไขบริการ</a>
                    และยินยอมให้จัดการข้อมูลส่วนบุคคลตาม<a style="color:#8553fe; text-decoration: underline;" target="_blank" href="https://line.teedev.online/files/PrivacyPolicy.pdf">นโยบายความเป็นส่วนตัว</a>
                  </label>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </div>
        <div style="display: flex; justify-content: center; margin: 0 0 50px 0;">
          <CButton v-on:click="Cancel()" style="background-color:#FF5C57; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
            <p style="margin: 0 10px;">ยกเลิก</p>
          </CButton>
          <CButton v-on:click="changeToMain()" style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
            <p style="margin: 0 10px;">ตกลง</p>
          </CButton>
        </div>
      </template> 
    </template>

     <!-- SMS Credit Failed -->
    <template v-else-if="page == 0">
      <div>
        <CCard style="width: 95%; margin: 20px auto;">
          <CCardBody style="background-color:#e5e5e5; padding: 10px;">      
            <div style="padding: 0 30px 0 30px;">
              <center>
                <h1 style="font-size:20px;">กรุณาติดต่อเจ้าหน้าที่</h1>
                <p style="font-size: 14px; color: #7d7d7d">
                  เนื่องจากพบปัญหา
                </p>
                <h2 style="font-size: 16px">SMS Credit Failed</h2>
                <p style="font-size: 14px; color: #7d7d7d">
                  โปรดรอสักครู...
                </p>
              </center>
            </div>
          </CCardBody>
        </CCard>
      </div>
    </template>


    <div v-else>
      <div>
        <v-skeleton-loader style="width: 100%; height: 140px;" type="image"></v-skeleton-loader>
      </div>
      <div style="display:flex; justify-content:space-around; margin: 10px;">
        <v-skeleton-loader style="width: 40%; height: 56px;" type="image"></v-skeleton-loader>
        <v-skeleton-loader style="width: 40%; height: 56px;" type="image"></v-skeleton-loader>
      </div>
      <div style="display:flex;">
        <div style="width: 30%;">
          <v-skeleton-loader style="width: 40px; height: 40px; border-radius: 50%; margin: auto;" type="image"></v-skeleton-loader>
        </div>
        <div style="width: 70%;">
          <v-skeleton-loader style="width: 60px; height: 40px;" type="image"></v-skeleton-loader>
        </div>
      </div>
      <div>
        <v-skeleton-loader style="width: 100%;  margin: 10px 0;" type="image"></v-skeleton-loader>
      </div>
    </div>

    <v-dialog
      v-model="forgetPin.dialog"
      width="500"
    >
      <v-card style="background-color:#FFF;">
        <div style="padding: 20px;">
          <center>
            <img src="../assets/image/UI Member PHOTO/Icon Popup-01.png" class="img-fluid" alt="Responsive image" style="width: 30%; padding-top: 20px; padding-bottom: 20px;"/>
            <h1 style="color: #000; font-size: 15px">ต้องการให้เราได้ส่งรหัส Pin ใหม่ไปที่เบอร์</h1>
            <h1 style="color: #000; font-size: 15px">{{numberphone}} ใช่หรือไม่ ?</h1>
            <CButton v-on:click="ForgotPassword()" style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
              <p style="margin: 0 10px;">ตกลง</p>
            </CButton>
          </center>
        </div>
      </v-card>
    </v-dialog>

  </div>
  </v-app>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";

import { freeSet } from "@coreui/icons";

const axios = require("axios");
import config from '../config'
import provinceTH from '../config/Province_th'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import "vue2-datepicker/locale/th";
import moment from "moment";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    DatePicker
  },
  data() {
    return {
      iconpage1_num: require('@/assets/image/UI Member PHOTO/Icon-08.png'),
      page: 1,
      pageww: "",
      branchWW: [{value:'0',label:'กดเพื่อเลือกสาขา'}],
      provincee : [],
  
      selected: "Month",
      profile: "",
      name: "",
      numberphone: "",
      branch: "",
      bod: null,
      refOTP: "",
      codeOTP:"",
      statusOTP:"",
      pinmode: false,
      menu: false,
      date: null,
      dateShow: "กดเพื่อใส่วันเดือนปีเกิด",
      checkDateValue: '',

      pin1:"",
      pin2:"",
      sex:"",
      showWrongPin:false,
      province:0,
      checkCondition:false,
      checkConditionlist:{"otp":false,"pin":false,"name":false,"sex":false,"bod":false,"province":false,"checkCondition":false},
      warningText : {"branch":'',"numberphone":'',"otp":'',"pin":'',"name":'',"sex":'',"bod":'',"province":'',"checkCondition":''},
      newuser: false,
      checkInput: {"branch":false,"numberphone":false},
      APIreply_Checkmemberstatus: {"refNumber":"","password":""},
      textCheck: {"userid":"","numberphone":"","pin":"","branch":""},
      infoUserId: {"userid":'','numberphone':'',"branch":'',"pin":'',"name":'','bod':'','sex':'','province':'','createpin':''},
      timeoutOtp:60,
      dataForgetPin: {timeoutForgetPin:0,css:"color:#000; margin: 0;"},
      cssOTP:"font-size: 13px; color: #7d7d7d",
      // registermember = {"userid":"","numberphone":"","pin":"","name":"","sex":"","bod_day":"","province":""},
      cssWraning:"background-color:#e5e5e5; padding: 10px;",
      checkmark:"background-color:#0008C1; ",
      forgetPin : {dialog:false,pin1:'',pin2:'',warningText:''}
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  mounted() {
    this.profile = this.$store.state.profile
    this.infoUserId.userid = this.profile.userId

    var listkey = Object.keys(this.$store.state.profile)   
    if(listkey.length <= 3){
      this.$router.push('/dashboard?pageww=member')
    }
    else{
      this.checkUser(this.profile).then((res) => {
        if(res.data.user == "ready"){
          this.$router.push('/dashboard?pageww=member')
        }
      })
    }

    //ส่ง API ขอสาขา
    this.API_machinelist().then((res) => {
      for(var i = 0; i < res.data.length; i++) {        
        res.data[i].label = res.data[i].value + res.data[i].label
        res.data[i].label = res.data[i].label.replace("Wonder Wash", " ");
        // console.log("data", res.data[i].label)
      }
      var payload = res.data
      payload.splice(0, 0, {value:'0',label:'กดเพื่อเลือกสาขา'});
      this.branchWW = [...payload]
    });

    this.provincee = provinceTH
    this.pageww = this.$route.query.pageww
  },
  methods: {
    SendOTPAgain(){
      if(this.timeoutOtp == 0){
        this.timeoutOtp = 60
        this.cssOTP = "font-size: 13px; color: #7d7d7d"
        this.changeToPage2()
      }
    },

    changeToPage2() {
      this.codeOTP = ""
      if(this.APIreply_Checkmemberstatus.refNumber !== '' && this.branch !== "" && this.branch !== '0' && this.numberphone[0] == '0'){
        let data = {"numberphone":this.numberphone}
        this.API_reqOTP(data).then((res) => {
        console.log("ststus SMS",res.data.status)
          if(res.data.status == true){
            console.log("SMS success")
            this.page = 2
            var timer =  setInterval(() => { 
              if(this.timeoutOtp != 0){
                --this.timeoutOtp
              }
              else{
                clearInterval(timer)
                this.cssOTP = "font-size: 13px; color: #000000"
              }
            }, 1000);
          }else{
            console.log("SMS Fail")
            this.page = 0
          }
        });
      }
      if(this.branch == ""){
        this.warningText.branch = "*เลือกสาขาที่ต้องการใช้งาน"
      }
      if(this.numberphone.length != 10 || this.numberphone[0] != '0'){
        this.warningText.numberphone = "*ใส่เบอร์โทรศัพท์มือถือ 10 หลัก"
      }
    },

    changeToPage3() {
      if(this.codeOTP.length == 6){
        let check_OTP = {
          "numberphone":this.numberphone,
          "codeOTP":this.codeOTP
        }
        this.API_checkOTP(check_OTP).then((res) => {
          this.statusOTP = res.data.status
          this.checkConditionlist.otp = !this.statusOTP
          if(this.statusOTP){
            this.infoUserId.numberphone = this.numberphone
            this.page = 3
          }
        })
      }
      else{
        this.statusOTP = false
        this.checkConditionlist.otp = !this.statusOTP
      }
    },

    Cancel(){
      this.page = 1
      this.pin1 = "",
      this.pin2 = "",
      this.branch =''
      this.numberphone = ''
      this.APIreply_Checkmemberstatus.refNumber = ''
      this.infoUserId = {"userid":'','numberphone':'',"branch":'',"pin":'',"name":'','bod':'','sex':'','province':'','createpin':''}
    },

    changeToMain() {
      const dateShow = moment(this.date).format('DD-MM-YYYY')
      console.log('dateShow', dateShow)
      console.log(this.checkConditionlist )

      this.bod = dateShow
      this.infoUserId.bod = dateShow

      if(this.codeOTP.length != 6){
        this.checkConditionlist.otp = true
      }
      else{
        this.checkConditionlist.otp = false
      }
      if(this.pin1.length != 4 && this.pin2 != 4){
        this.checkConditionlist.pin = true
      }
      else{
        this.checkConditionlist.pin = false
      }
      if(this.name==""){
        this.checkConditionlist.name = true
      }
      else{
        this.checkConditionlist.name = false
      }
      if(this.sex==""){
        this.checkConditionlist.sex = true
      }
      else{
        this.checkConditionlist.sex = false
      }
      if(this.date == null){
        this.checkConditionlist.bod = true
      }
      else{
        this.checkConditionlist.bod = false
      }
      if(this.province==0){
        this.checkConditionlist.province = true
      }
      else{
        this.checkConditionlist.province = false
      }
      if (this.checkConditionlist.checkCondition == true) {
        this.cssWraning = "background-color:#e5e5e5; color: #000; padding: 10px;"
      } else {
        this.cssWraning = "background-color:#e5e5e5; color: #F9534E; padding: 10px;"
        this.checkmark = "background-color:#F9534E;"
      }

      if(!this.checkConditionlist.otp && !this.checkConditionlist.pin && !this.checkConditionlist.name && !this.checkConditionlist.sex && !this.checkConditionlist.otp && !this.checkConditionlist.bod && !this.checkConditionlist.province && this.checkConditionlist.checkCondition == true){
        this.page = 4
        this.API_sendInfoUser(this.infoUserId).then((res) => {
          this.$store.state.profile.numberphone = this.numberphone
          this.$store.state.profile.nemeuser = this.name
          this.$store.state.profile.branch = this.branch
          this.$store.state.profile.sex = this.sex
          // this.$store.state.profile.setting = {AskPinLine: "1", AskPinKiosk: "0", LineNotification: "0", SMSNotification: "0"}
          this.$store.state.profile.setting = {AskPinLine: "0", AskPinKiosk: "0", LineNotification: "0", SMSNotification: "0"}

          //  this.$store.state.popupregis = res.data.promotion
          this.$store.state.popupregis_buff = res.data.promotion
          if(res.data.status){
            this.$store.state.showNav = true
            if(this.pageww == "setting"){
              this.$router.push('/settingww')
            }
            else if(this.pageww == "member"){
              this.$router.push('/memberww')
            }
            else if(this.pageww == "reward"){
              this.$router.push('/rewardww')
            }
            else if(this.pageww == "new"){
              this.$router.push('/newsww')
            }
            else if(this.pageww == "operation"){
              this.$router.push('/operationww')//
            }
            else if(this.pageww == "status"){
              this.$router.push('/operationww')//
            }
            else{
              this.$router.push('/memberww')
            }
            // this.$router.push("/rewardww");
            // location.replace('https://liff.line.me/1654151540-Q1myp46Z'); //setting page
          }
          else{
            this.warningText.pin = "*รหัสผ่านไม่ถูกต้อง"
          }
        })
      }

      if(this.infoUserId.createpin && this.infoUserId.name=="" && this.infoUserId.sex=="" && this.infoUserId.bod=="" && this.infoUserId.province==""){
        this.API_sendInfoUser(this.infoUserId).then((res) => {
          if(res.data.comparepin){
            if(this.pageww == "setting"){
              this.$router.push('/settingww')
            }
            else if(this.pageww == "member"){
              this.$router.push('/memberww')
            }
            else if(this.pageww == "reward"){
              this.$router.push('/rewardww')
            }
            else if(this.pageww == "new"){
              this.$router.push('/newww')
            }
            else if(this.pageww == "operation"){
              this.$router.push('/operationww')//
            }
            else if(this.pageww == "status"){
              this.$router.push('/statusww')//
            }
            else{
              this.$router.push('/memberww')
            }
            // this.$router.push("/rewardww");
            // location.replace('https://liff.line.me/1654151540-Q1myp46Z'); //setting page
          }
        })
      }

    },

    onChangeBranch (e) {
      const { value } = e.target
      this.branch = value
      this.infoUserId.branch = this.branch
      this.warningText.branch = ""
      this.cntnumberPhone()
    },

    cntnumberPhone (){
      if(this.branch != 0 && this.numberphone.length == 10){
        this.API_Checkmemberstatus(this.numberphone,this.branch,this.profile.userId).then((res) => {
          this.APIreply_Checkmemberstatus.refNumber = res.data.refNumber
          this.APIreply_Checkmemberstatus.password = res.data.password
          this.infoUserId.createpin = res.data.password
          if(res.data.numberphoneDB){
            this.textCheck.numberphone = 'มีเบอร์ของคุณในระบบ' //เบอร์ที่ linebot เก็บ ?
            this.iconpage1_num = require('@/assets/image/UI Member PHOTO/Icon-08.png')
          }
          else{
            this.textCheck.numberphone = 'เบอร์ของคุณ เข้าสู่ระบบเป็นครั้งแรก'
            this.iconpage1_num = require('@/assets/image/UI Member PHOTO/Icon-06.png')
          }
          if(this.APIreply_Checkmemberstatus.password){
            this.textCheck.pin = 'คุณตั้งรหัส Pin แล้ว'
            this.iconpage1_pin = require('@/assets/image/UI Member PHOTO/Icon-08.png')
            this.pinmode = false
          }
          else{
            this.textCheck.pin = 'คุณยังไม่ได้ตั้งรหัส Pin'
            this.iconpage1_pin = require('@/assets/image/UI Member PHOTO/Icon-07.png')
            this.pinmode = true
          }
        })
      }
      
      if(this.numberphone.length != 10 || this.numberphone[0] != '0'){
        this.APIreply_Checkmemberstatus.refNumber = ''
        this.warningText.numberphone = "*ใส่เบอร์โทรศัพท์มือถือ 10 หลัก"
      }
      else{
        this.warningText.numberphone = ""
      }
    },

    API_Checkregister(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/checkregister", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    SelectSex(){
      var radios = document.getElementsByName('sex');
      for (var i = 0, length = radios.length; i < length; i++) {
        if (radios[i].checked) {
          this.sex = radios[i].value
          this.infoUserId.sex = this.sex
          break;
        }
      }
    },

    checkPin(){
      if(!this.pinmode){//ใช้ pin เดิม
        if(this.pin1.length == 4){
          this.infoUserId.pin = this.pin1
          let data = {
            "numberphone": this.numberphone,
            "branch": this.branch,
            "pin": this.infoUserId.pin
          }
          this.API_Login(data).then((res)=>{
            if(res.data.comparepin){
              this.newuser = res.data.comparepin
              this.warningText.pin = ''
            }
            else{
              this.warningText.pin = "*รหัสผ่านไม่ถูกต้อง"
            }
          })
        }
        else{
          this.warningText.pin = ""
        }
      }
      else{//สร้าง pin ใหม่
        if(this.pin1 != this.pin2 && this.pin1.length==4 && this.pin2.length==4){
          this.showWrongPin = true
          this.checkConditionlist.pin = true
        }
        else{
          if(this.pin1.length == 4 && this.pin2.length==4){
            this.showWrongPin = false
            this.checkConditionlist.pin = false
            this.infoUserId.pin = this.pin1
            let data = {
              "numberphone": this.numberphone,
              "rqPasswordKiosk": true,
              "pin": this.infoUserId.pin,
              "branch":this.infoUserId.branch
            }
            this.API_Createuser(data).then((res)=>{
              this.newuser = res.data.status
            })
          }
          else{
            this.showWrongPin = false
            this.checkConditionlist.pin = false
            this.warningText.pin = ""
          }
        }
      }
    },

    checkName(){
      this.infoUserId.name = this.name
    },

    Province(e){
      this.province= e.target.value
      this.infoUserId.province = this.province
    },

    checkBox() {
      this.checkConditionlist.checkCondition = document.getElementById("checkCondition").checked;
    },

    save(date) {
      this.dateShow = date.substring(8, 10) + "/" + date.substring(5, 7) + "/" +(parseInt(date.substring(0, 4)) + 543).toString();
      this.$refs.menu.save(date)
      this.bod = this.dateShow
      this.infoUserId.bod = this.dateShow
    },

    DialogForgotPassword(){
      if(this.dataForgetPin.timeoutForgetPin == 0){
        this.forgetPin.dialog = true
      }
    },

    ForgotPassword(){
      this.forgetPin.dialog = false
      if(this.dataForgetPin.timeoutForgetPin == 0){
        this.dataForgetPin.timeoutForgetPin = 180
        this.dataForgetPin.css = "color:#959daa; margin: 0;"
        var timer2 =  setInterval(() => { 
          if(this.dataForgetPin.timeoutForgetPin != 0){
            --this.dataForgetPin.timeoutForgetPin
          }
          else{
            clearInterval(timer2)
            this.dataForgetPin.css = "color:#000; margin: 0;"
          }
        }, 1000);
        let data =  {
          "userId": this.profile.userId,
          "numberphone": this.numberphone,
          "branch":this.infoUserId.branch
        }
        this.API_ForgetPin(data).then((res)=>{})
      }
    },

    CancelDialog(){
      this.forgetPin = { dialog:false,pin1:'',pin2:'',warningText:'' }
    },

    ResetPin(){
      if(this.forgetPin.pin1 == this.forgetPin.pin2 && this.forgetPin.pin1.length == 4){
        let data =  {
          "userId": this.profile.userId,
          "numberphone": this.numberphone,
          "branch":this.infoUserId.branch
        }
        this.API_ForgetPin(data).then((res)=>{
          this.newuser = res.data.status
          this.infoUserId.pin = this.forgetPin.pin1
          this.pin1 = this.forgetPin.pin1
          this.pin2 = this.forgetPin.pin1
          this.CancelDialog()
        })
      }
      if(this.forgetPin.pin1 == this.forgetPin.pin2 && this.forgetPin.pin1.length > 4){
        this.forgetPin.warningText = "*กำหนด Pin ด้วยตัวเลข 4 หลัก"
      }
      if(this.forgetPin.pin1 != this.forgetPin.pin2){
        this.forgetPin.warningText = "*กรอก pin ให้ตรงกัน"
      }
    },

    API_ForgetPin(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/forgetpin", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_Createuser(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/memberpin", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_Login(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/login", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_machinelist(){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json"};
        axios.get(config.server+"/machinelist",{
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_Checkmemberstatus(refNumber,machineId,datauserid){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json"};
        axios.get(config.server+'/checkmemberstatus/?refNumber='+refNumber+'&machineId='+machineId+'&userid='+datauserid,{
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_reqOTP(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/reqotp", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_checkOTP(codeOTP){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/checkotp", codeOTP, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_sendInfoUser(infodata){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/registeruser", infodata, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    checkUser(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/checkuser", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

  },
};
</script>
<style>

</style>



